<form [formGroup]="formGroup" (submit)="submit()">
  <k-row class="search">
    <ng-template #row>
      <input type="text" [placeholder]="placeholder" formControlName="search">
      <ng-container *ngIf="mutableSearch">
        <mat-form-field appearance="fill" class="search-type" formGroupName="mutableSearch">
          <mat-select disableOptionCentering formControlName="type">
            <mat-option *ngFor="let item of mutableSearchOption" [value]="item.type">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <button type="submit">
        <k-icon name="search"></k-icon>
        Найти
      </button>
      <a (click)="toggle()" class="popup-wr__lnk-extended"
         [style.display]="extended||!filterFields.length  ? 'none' : 'block'" #extendBtn>Расширенный поиск</a>
    </ng-template>
  </k-row>

  <div class="search-detals" [style.display]="extended && filterFields.length ? 'block' : 'none'" #searchDetails>
    <div class="form-row form-row--column" *ngFor="let row of filterFields">
      <ng-container *ngFor="let field of row">
        <div class="form-span"
             [ngClass]="field.class"
             [style]="field.style"
             [style.display]="(!mutableSearch ||
                            mutableSearch && field.mutableSearchType === formGroup.get('mutableSearch.type').value) ? 'table-cell' : 'none'">
          <div class="form-head"
               *ngIf="field.head"
               [ngClass]="field.class"
               [style]="field.style">{{field.head.value}}</div>
          <div *ngIf="field.loading" class="form-body"><span class="loader"></span></div>
          <!--               [ngClass]="field.body?.class"-->

          <div class="form-body" [style]="field.body?.style" *ngIf="!field.loading">
            <ng-container [ngSwitch]="field.type">
              <ng-container *ngSwitchCase="fieldTypes.text">
                <input type="text" class="inp-st"
                       [style]="field.attributes?.style"
                       [ngClass]="field.attributes?.class" [formControl]="formGroup.get('report.' + field.prop)">
              </ng-container>
              <ng-container *ngSwitchCase="fieldTypes.date">
                <!--                <input type="text" class="inp-st datepicker" appUiDatepicker-->
                <!--                       [style]="field.attributes?.style"-->
                <!--                       [ngClass]="field.attributes?.class" [formControl]="formGroup.get('report.' + field.prop)">-->
                <app-datepicker-overview
                  [formControl]="formGroup.get('report.' + field.prop)"
                ></app-datepicker-overview>
              </ng-container>

              <ng-container *ngSwitchCase="fieldTypes.select">
<!--                <select app-ui-select-field-->
<!--                        [style]="field.attributes?.style"-->
<!--                        [options]="field.attributes?.options || []"-->
<!--                        [nullTitle]="field.prop === 'appointment.paymentType' ? PaymentTypeEnum.options[0].name : 'Все'"-->
<!--                        [ngClass]="field.attributes?.class" [formControl]="formGroup.get('report.' + field.prop)">-->
                  <!-- <option [value]="null">Все</option>
                   <option *ngFor="let option of field.attributes?.options || []" [value]="option.id">{{option.name}}</option>-->
<!--                </select>-->
              </ng-container>

              <ng-container *ngSwitchCase="fieldTypes.autocomplete">
                <ng-container *ngIf="field.attributes?.optionsType !== crudType.ReferenceBreed">
                  <app-ui-autocomplete
                    [control]="formGroup.get('report.' + field.prop)"
                    [type]="field.attributes?.optionsType"
                    [field]="field.field"
                  ></app-ui-autocomplete>
                </ng-container>
                <ng-container
                  *ngIf="field.attributes?.optionsType === crudType.ReferenceBreed && (field.attributes.optionsFilter | async).length">
                  <app-ui-autocomplete
                    [control]="formGroup.get('report.' + field.prop)"
                    [type]="field.attributes?.optionsType"
                    [petId]="(field.attributes.optionsFilter | async) || []"
                  ></app-ui-autocomplete>
                </ng-container>
                <ng-container
                  *ngIf="field.attributes?.optionsType === crudType.ReferenceBreed && !(field.attributes.optionsFilter | async).length">
                  <input type="text" class="inp-st" disabled>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="fieldTypes.number">
                <input type="text" class="inp-st" appUiMaskNumber
                       [maskNumberMin]="field.attributes?.min"
                       [maskNumberMax]="field.attributes?.max"
                       [style]="field.attributes?.style"
                       [ngClass]="field.attributes?.class" [formControl]="formGroup.get('report.' + field.prop)">
              </ng-container>
              <ng-container *ngSwitchCase="fieldTypes.multiSelect">
                <!--                {{formGroup.get('report.' + field.prop)}}-->
<!--                <app-ui-mat-multi-select *ngIf="!mutableSearch || this.formGroup.get('mutableSearch').get('type').value === field.mutableSearchType"-->
<!--                                           [control]="formGroup.get('report.' + field.prop)"-->
<!--                                           [choices]="field.attributes?.options"-->
<!--                                           [searchInput]="true"-->
<!--                ></app-ui-mat-multi-select>-->
              </ng-container>
              <ng-container *ngSwitchCase="fieldTypes.checkbox">
                <div class="check-st">
                  <input id="{{field.prop}}" type="checkbox" [style]="field.attributes?.style"
                         [ngClass]="field.attributes?.class" [formControl]="formGroup.get('report.' + field.prop)">
                  <label for="{{field.prop}}">{{field.attributes?.label}}</label>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>

    </div>
    <div class="search-detals__close"><a (click)="toggle()">Скрыть расширенный поиск</a></div>
  </div>
</form>
